/*
 *Displaying one card with the product from TopSelling sectoion on the home page
 */
import {
  IonCol,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCardHeader,
  IonCardSubtitle,
  IonRouterLink,
} from "@ionic/react";
import Button from "../../../Button/Button";
import "./TopSellingProductItem.css";
import { IProductOptions } from "../../../../types/IProductOptions";

interface IProductItemsParams {
  item: IProductOptions;
}

const TopSellingProductItem: React.FC<IProductItemsParams> = ({ item }) => {
  return (
    <IonCol size="12" size-md="6" size-xl="4" class="featured-card-container">
      <IonRouterLink
        routerLink={`/order?styleid=${item.id}`}
        routerDirection="none"
      >
        <IonCard className="featured-card">
          <IonCardHeader>
            <IonCardTitle className="card-title">{item.adminName}</IonCardTitle>
            <IonCardSubtitle className="card-subtitle">
              {item.optionDescription ? item.optionDescription : ""}
            </IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent className="featured-content">
            <img
              className="featured-img-item"
              src={`storage/${item.swatchValue}`}
              alt={item.adminName}
            />
          </IonCardContent>
          <Button text="Order Now" />
        </IonCard>
      </IonRouterLink>
    </IonCol>
  );
};

export default TopSellingProductItem;
