/*
 *displaying array with the products from TopSelling section
 */
import { IonRow } from "@ionic/react";
import TopSellingProductItem from "./components/TopSellingProductItem/TopSellingProductItem";
import { IProductOptions } from "../../types/IProductOptions";

interface ITopSellingProductsListParams {
  list: IProductOptions[];
}

const ProductList: React.FC<ITopSellingProductsListParams> = ({ list }) => {
  // Sort the list by sortOrder
  const sortedList = list.slice().sort((a, b) => a.sortOrder - b.sortOrder);

  return (
    <IonRow>
      {sortedList.map((product: IProductOptions, index) => (
        <TopSellingProductItem item={product} key={index} />
      ))}
    </IonRow>
  );
};

export default ProductList;
