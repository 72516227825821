import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonIcon,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import axios from "axios";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import SectionTitle from "../../components/Titles/SectionTitle";
import {
  logoFacebook,
  logoInstagram,
  logoXing,
  logoPinterest,
  logoTiktok,
  logoLinkedin,
} from "ionicons/icons";

const UpsellVerification: React.FC = () => {
  //const history = useHistory();
  const location = useLocation();
  const [verificationStatus, setVerificationStatus] = useState(
    "Verifying Upsell Offer",
  );
  const [upselVerified, setUpsellVerified] = useState(false);
  const [orderId, setOrderId] = useState<string | null>(null);
  const [quantity, setQuantity] = useState<string>("");
  const [signature, setSignature] = useState<string | null>(null);
  const [expires, setExpires] = useState<string>("");

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    setOrderId(query.get("order_id"));
    setQuantity(query.get("quantity") || "");
    setSignature(query.get("signature"));
    setExpires(query.get("expires") || "");
  }, []);

  const verifyUpsell = () => {
    // Send the order ID and quantity to the backend to verify the signed link
    axios
      .request({
        method: "POST",
        url: "/graphql",
        headers: { "Content-type": "application/json" },
        data: {
          query: `
          mutation VerifyUpsellLink($orderId: String!, $quantity: Int!, $signature: String!, $expires: Int!) {
            verifyUpsellLink(
              order_id: $orderId,
              quantity: $quantity,
              signature: $signature,
              expires: $expires
            ) {
              success
              message
            }
          }`,
          variables: {
            orderId: orderId,
            quantity: parseInt(quantity, 10),
            signature: signature,
            expires: parseInt(expires, 10),
          },
        },
      })
      .then((res) => {
        if (res.data && res.data.data.verifyUpsellLink) {
          setUpsellVerified(true);
          const response = res.data.data.verifyUpsellLink;
          setVerificationStatus(response.message);
        }
      })
      .catch((err) => {
        console.log("Error verifying upsell link", err);
        setVerificationStatus("Upsell verification failed.");
      });
  };

  useEffect(() => {
    if (!upselVerified && orderId && signature) {
      verifyUpsell();
    }
  }, [upselVerified, orderId, quantity, signature, expires]);

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen color="light">
        <section className="page-section">
          <div className="fixed-container">
            <SectionTitle
              title={
                <h2>
                  <span className="title-bold">Upsell Offer Verification</span>
                </h2>
              }
              class="title-dark"
            />
          </div>
          <div className="fixed-container fixed-container-white small-paddings">
            <IonGrid className="ion-padding">
              <IonRow className="ion-margin-top ion-padding-top ion-justify-content-center">
                <IonCol
                  size="12"
                  size-md="6"
                  size-lg="6"
                  offset-lg="1"
                  offset-md="2"
                  className="text-align-left"
                >
                  <h4>{verificationStatus}</h4>
                  {verificationStatus.includes("redeemed successfully") ? (
                    <div className="social-media-invite">
                      <p>
                        We’re excited to invite you to follow us on social media
                        to stay up to date with the latest offers, new product
                        launches, and fresh ideas for your upcoming projects. By
                        following us, you can get access to exclusive promotions
                        and creative inspiration to help bring your next project
                        to life.
                      </p>
                      <p>
                        Join our community today to stay informed and inspired!
                      </p>
                      <p>Follow us on:</p>
                      <ul>
                        <li>
                          <a
                            href="https://www.facebook.com/rapidwristbands/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IonIcon icon={logoFacebook} /> Facebook
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/rapidwristbands/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IonIcon icon={logoInstagram} /> Instagram
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://x.com/RapidWristbands"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IonIcon icon={logoXing} /> X
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.pinterest.com/rapidwristbands/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IonIcon icon={logoPinterest} /> Pinterest
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.tiktok.com/@rapidwristbands"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IonIcon icon={logoTiktok} /> TikTok
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.linkedin.com/company/rapid-wristbands"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IonIcon icon={logoLinkedin} /> LinkedIn
                          </a>
                        </li>
                      </ul>
                      <p>
                        Thank you for being a valued part of our community. We
                        can’t wait to connect with you online!
                      </p>
                      <p>
                        Have any questions? Contact us at{" "}
                        <a href="mailto:support@rapidwristbands.com">
                          support@rapidwristbands.com
                        </a>
                        .
                      </p>
                    </div>
                  ) : (
                    <p>
                      Please contact us at{" "}
                      <a href="mailto:support@rapidwristbands.com">
                        support@rapidwristbands.com
                      </a>{" "}
                      or at 1-800-523-8078 with any questions.
                    </p>
                  )}
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </section>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default UpsellVerification;
