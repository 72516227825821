// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.field:not(:last-child) {
  margin-bottom: 1rem !important;
}

.customInput {
  margin-top: 0.25rem;
  transition: all 0.2s linear;
  width: 100%;
  min-width: 50px;
  border: 1px solid #eaeaea;
  color: #92949c;
  margin: 7px 7px 0 0;
  background-color: #fff;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
  --padding-start: 10px;
}

.customSelect {
  margin-top: 0.25rem;
  max-width: 300px;
  min-width: 50px;
  border: 1px solid #eaeaea;
  background-color: #fff;
}

.customTextarea {
  margin-top: 0.25rem;
  width: 100%;
  min-width: 50px;
  border: 1px solid #eaeaea;
  background-color: #fff;
}

.errorBorder {
  border: 1px solid #eb445a;
}

.errorText {
  color: #eb445a !important;
  font-size: 13px !important;
  margin-top: 5px !important;
}

/****Media****/
@media (max-width: 991px) {
  .field ion-label {
    padding-left: 0.2rem;
    padding-right: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
  }
  .customInput .customSelect .customTextarea {
    max-width: 270px;
  }
}
@media (max-width: 765px) {
  .customInput .customSelect .customTextarea {
    max-width: 100%;
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/FormInputs/CustomField.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;AAChC;;AAEA;EACE,mBAAmB;EACnB,2BAA2B;EAC3B,WAAW;EACX,eAAe;EACf,yBAAyB;EACzB,cAAc;EACd,mBAAmB;EACnB,sBAAsB;EACtB,+CAA+C;EAC/C,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,eAAe;EACf,yBAAyB;EACzB,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,eAAe;EACf,yBAAyB;EACzB,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,0BAA0B;EAC1B,0BAA0B;AAC5B;;AAEA,cAAc;AACd;EACE;IACE,oBAAoB;IACpB,qBAAqB;IACrB,aAAa;IACb,8BAA8B;IAC9B,qBAAqB;IACrB,mBAAmB;EACrB;EACA;IACE,gBAAgB;EAClB;AACF;AACA;EACE;IACE,eAAe;IACf,WAAW;EACb;AACF","sourcesContent":[".field:not(:last-child) {\n  margin-bottom: 1rem !important;\n}\n\n.customInput {\n  margin-top: 0.25rem;\n  transition: all 0.2s linear;\n  width: 100%;\n  min-width: 50px;\n  border: 1px solid #eaeaea;\n  color: #92949c;\n  margin: 7px 7px 0 0;\n  background-color: #fff;\n  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);\n  --padding-start: 10px;\n}\n\n.customSelect {\n  margin-top: 0.25rem;\n  max-width: 300px;\n  min-width: 50px;\n  border: 1px solid #eaeaea;\n  background-color: #fff;\n}\n\n.customTextarea {\n  margin-top: 0.25rem;\n  width: 100%;\n  min-width: 50px;\n  border: 1px solid #eaeaea;\n  background-color: #fff;\n}\n\n.errorBorder {\n  border: 1px solid #eb445a;\n}\n\n.errorText {\n  color: #eb445a !important;\n  font-size: 13px !important;\n  margin-top: 5px !important;\n}\n\n/****Media****/\n@media (max-width: 991px) {\n  .field ion-label {\n    padding-left: 0.2rem;\n    padding-right: 0.5rem;\n    display: flex;\n    justify-content: space-between;\n    align-content: center;\n    align-items: center;\n  }\n  .customInput .customSelect .customTextarea {\n    max-width: 270px;\n  }\n}\n@media (max-width: 765px) {\n  .customInput .customSelect .customTextarea {\n    max-width: 100%;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
