/*
 *Displaying the card with the product on the home page.
 */
import {
  IonCol,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCardHeader,
  IonButton,
  IonCardSubtitle,
  IonIcon,
  IonRouterLink,
} from "@ionic/react";
import arrow from "../../../../assets/icon/arrow.svg";
import "./ProdictItem.css";
import { IProductOptions } from "../../../../types/IProductOptions";

interface IProductItemsParams {
  item: IProductOptions;
}

const ProductItem: React.FC<IProductItemsParams> = (props) => {
  return (
    <IonCol size="12" size-md="6" size-xl="4">
      {/*the card is inside the lisnk to the detailsef description of the product*/}
      <IonRouterLink
        routerLink={`/order?styleid=${props.item.id}`}
        routerDirection="none"
      >
        <IonCard className="card">
          <IonCardHeader>
            <IonCardTitle className="card-title">
              {props.item.adminName}
            </IonCardTitle>
            <IonCardSubtitle className="card-subtitle">
              <div>{props.item.optionDescription}</div>
            </IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <img
              className="img-item"
              src={`storage/${props.item.swatchValue}`}
              alt={props.item.adminName}
            />
          </IonCardContent>
          <div className="buttons-options">
            <IonButton color="secondary" className="btn" fill="clear">
              Order Now
            </IonButton>
            <IonButton className="button-tr" fill="clear">
              <IonIcon icon={arrow} size="large" />
            </IonButton>
          </div>
        </IonCard>
      </IonRouterLink>
    </IonCol>
  );
};

export default ProductItem;
