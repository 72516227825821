import { IonContent, IonPage } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
/*Import Components */
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import TextBlock from "../../components/TextBlocks/TextBlock";
import TextBlockGray from "../../components/TextBlocks/TextBlockGray";
import TopSellingProductList from "../../components/TopSellingProductList/TopSellingProductList";
import TrustingCompaniesList from "../../components/TrustingCompaniesList/TrustingCompaniesList";
import ProductListSlider from "../../components/ProductList/ProductListSlider";
import ProductList from "../../components/ProductList/ProductList";
import SectionTitle from "../../components/Titles/SectionTitle";
import { OffersList } from "../../components/List/OffersList";
import List from "../../components/List/List";
import Button from "../../components/Button/Button";
import MainButton from "../../components/Button/MainButton";

/*Import content - mockups */
import TrustingCompaniesListMocs from "../../fixtures/TrustingCompaniesListMocs";
import FirstBlockMocs from "../../fixtures/homePageTexts/FirstBlockMocs";
import SecondBlockMocs from "../../fixtures/homePageTexts/SecondBlockMocs";
import ThirdBlockMocs from "../../fixtures/homePageTexts/ThirdBlockMocs";

/* Import CSS*/
import "./Home.css";
import AdBanner from "../../components/AddBanner/AddBanner";
import getAttributeOptions from "../../services/attributes/getAttributeOptions";

const Home: React.FC = () => {
  /*Getting Products**/
  const [productList, setProductList] = useState([]);
  const [featuredProducts, setFeaturedProducts] = useState([]);
  useEffect(() => {
    getAttributeOptions("band_style").then((response: any) => {
      setProductList(response.data.data.attributes.data[0].options);
    });
  }, []);

  useEffect(() => {
    if (productList.length > 0) {
      const featured = productList.filter(
        (product: any) => product.isFeatured === true,
      );
      setFeaturedProducts(featured);
    }
  }, [productList]);

  /*Listening the screen size*/
  const [mQuery, setMQuery] = React.useState<any>({
    matches: window.innerWidth > 768 ? true : false,
  });

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 768px)");
    mediaQuery.addEventListener("change", setMQuery);
    return () => mediaQuery.removeEventListener("change", setMQuery);
  }, []);

  return (
    <IonPage>
      <Header />
      <IonContent>
        {/********First Screen********/}
        {mQuery && !mQuery.matches ? (
          <AdBanner />
        ) : (
          <section className="first-screen">
            <IonGrid>
              <IonRow>
                <IonCol size="0" size-md="4" size-lg="5" size-xl="6"></IonCol>
                <IonCol size="12" size-md="8" size-lg="7" size-xl="6">
                  <h1 className="first-screen-heading">Don't let your</h1>
                  <img src="../../../assets/img/message.svg" alt="message" />
                  <div className="main-button-container">
                    <h1 className="first-screen-heading">
                      get lost in the crowd
                    </h1>
                    <MainButton text="Order Now!" />
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </section>
        )}
        {/********Reviews********/}
        <section className="reviews">
          <div className="container">
            <div className="reviews-badgets">
              <div className="reviews-img">
                <a
                  href="https://www.bbb.org/us/tx/houston/profile/wearable-promotional-products/rapid-wristbands-0915-90043132/#sealclick"
                  target="_blank"
                  rel="nofollow"
                >
                  <img
                    src="https://seal-houston.bbb.org/seals/blue-seal-200-42-bbb-90043132.png"
                    style={{ border: 0, height: 35 }}
                    alt="Rapid Wristbands BBB Business Review"
                  />
                </a>
              </div>
              <div className="reviews-img">
                <a
                  href="https://www.shopperapproved.com/reviews/rapidwristbands.com"
                  target="blank"
                  className="shopperlink new-sa-seals placement-856"
                >
                  <img
                    src="https://www.shopperapproved.com/seal/16600/856-sa-seal.gif"
                    style={{ borderRadius: 4, height: 35 }}
                    alt="Customer Reviews"
                    onContextMenu={() => {
                      const d = new Date();
                      alert(
                        `Copying Prohibited by Law - This image and all included logos are copyrighted by Shopper Approved \\251 ${d.getFullYear()}.`,
                      );
                      return false;
                    }}
                  />
                </a>
              </div>
            </div>
          </div>
        </section>
        {/********Top Selling Products********/}
        <section className="top-selling">
          <div className="container">
            <SectionTitle
              title={
                <h2>
                  Our <span className="title-bold">Top Selling</span> Wristbands
                </h2>
              }
              class="title-dark"
            />
            <IonGrid>
              {featuredProducts.length > 0 && (
                <TopSellingProductList list={featuredProducts.slice(0, 3)} />
              )}
            </IonGrid>
          </div>
        </section>
        {/********Product Options********/}
        <section className="options">
          <div className="container">
            <SectionTitle
              title={
                <h2>
                  Our <span className="title-bold">Wristbands</span> Options
                </h2>
              }
              class="title-light"
            />

            {mQuery && !mQuery.matches ? (
              <>
                {productList.length > 0 && (
                  <ProductListSlider list={productList} />
                )}
              </>
            ) : (
              <>
                {productList.length > 0 && <ProductList list={productList} />}
              </>
            )}
          </div>
        </section>
        {/********Text Blocks********/}
        <section className="text block-with-bottom-border">
          <div className="container container-text">
            <SectionTitle
              title={
                <h2>
                  <span className="title-bold">Custom</span> Silicone Rubber{" "}
                  <br />
                  Bracelets & Paper Wristbands
                </h2>
              }
              class="title-dark"
            />
            <IonGrid>
              <IonRow>
                <IonCol size="12" size-md="6">
                  <TextBlock text={FirstBlockMocs} />
                </IonCol>
                <IonCol size="12" size-md="6">
                  <TextBlock text={SecondBlockMocs} />
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </section>
        <section className="text">
          <div className="container container-text">
            <IonGrid>
              <IonRow>
                <IonCol size="12" size-md="6">
                  <div className="text-block">
                    <p>
                      <strong>
                        To own your own wristband, make sure to use the options
                        above to customize your bracelet! We offer:
                      </strong>
                    </p>
                    {OffersList.length > 0 && <List list={OffersList} />}
                    <Button text="Design Custom Wristbands" />
                  </div>
                </IonCol>
                <IonCol size="12" size-md="6" class="ion-hide-md-down">
                  <TextBlockGray text={ThirdBlockMocs} />
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </section>
        <section className="gray-block-for-small-screen ion-hide-md-up">
          <div className="container">
            <IonGrid>
              <IonRow>
                <IonCol size="12">
                  <TextBlockGray text={ThirdBlockMocs} />
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </section>
        <section className="trusted">
          <div className="container">
            <SectionTitle
              title={
                <h2>
                  <span className="title-bold">Trusted</span>
                  <span className="lowercase"> by</span>
                </h2>
              }
              class="title-dark"
            />
            {TrustingCompaniesListMocs.length > 0 && (
              <TrustingCompaniesList list={TrustingCompaniesListMocs} />
            )}
          </div>
        </section>
        {/********Footer********/}
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default Home;
