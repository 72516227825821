import { IonRow, IonGrid } from "@ionic/react";
import ProductItem from "./components/ProductItem/ProductItem";
import { IProductOptions } from "../../types/IProductOptions";

interface IProdictsListParams {
  list: IProductOptions[];
}

const ProductList: React.FC<IProdictsListParams> = (props) => {
  return (
    <IonGrid>
      <IonRow>
        {props.list?.map((product: IProductOptions, index) => (
          <ProductItem item={product} key={index} />
        ))}
      </IonRow>
    </IonGrid>
  );
};

export default ProductList;
