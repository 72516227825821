import React, { ReactNode } from "react";
import { Redirect, Route } from "react-router-dom";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import "./App.css";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import Home from "./pages/Home/Home";
import Wristbands from "./pages/Wristbands/Wristbands";
import Prices from "./pages/Prices/Prices";
import Occasions from "./pages/Occasions/Occasions";
import Blog from "./pages/Blog/Blog";
import Privacy from "./pages/Privacy/Privacy";
import Resources from "./pages/Resources/Resources";
import ProductStylePage, {
  RouteProductStyle,
} from "./pages/ProductStylePage/ProductStylePage";
import TyvekWristbands from "./pages/TyvekWristbands/TyvekWristbands";
import About from "./pages/About/About";
import { RouteOccasions } from "./pages/Occasions/Occasions";
import FAQ from "./pages/FAQ/FAQ";
import Gallery from "./pages/Gallery/Gallery";
import Register from "./pages/Auth/Register";
import Login from "./pages/Auth/Login";
import Webhook from "./pages/Webhook/Webhook";
import ProductPage, { RouteProductPage } from "./pages/ProductPage/ProductPage";
import Account from "./pages/Account/Account";
import { CurrentCustomerProvider } from "./contexts/currentCustomer";
import EmailVerification from "./pages/Auth/EmailVerification";
import SocialLogin from "./pages/Auth/SocialLogin";
import { CartProvider } from "./contexts/cart";
import Cart from "./pages/Cart/Cart";
import Menu from "./components/Menu/Menu";
import NotFoundComponent from "./pages/404/NotFoundComponent";
import OrderPage from "./pages/OrderPage/OrderPage";
import FreeSample from "./pages/FreeSample/FreeSample";
import CheckoutPage from "./pages/CheckoutPage/CheckoutPage";
import Receipt from "./pages/Receipt/Receipt";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import ResetPassword from "./pages/Auth/ResetPassword";
import UpsellVerification from "./pages/OrderPage/UpsellVerification";
import GetQuote from "./pages/GetQuote/GetQuote";

setupIonicReact();

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute: React.FunctionComponent<ProtectedRouteProps> = ({
  children,
}) => {
  const currentDomain = window.location.host;
  if (document.referrer && !document.referrer.includes(currentDomain)) {
    return <Redirect to="/404" />;
  }
  return <>{children}</>;
};

const App: React.FC = () => (
  <IonApp>
    <CurrentCustomerProvider>
      <CartProvider>
        <IonReactRouter>
          <Menu />
          <IonRouterOutlet id="main">
            <Route exact path="/home">
              <Home />
            </Route>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/wristbands">
              <Wristbands />
            </Route>
            <Route exact path="/occasions">
              <Occasions />
            </Route>
            <Route exact path="/blog">
              <Blog />
            </Route>
            <Route exact path="/about">
              <About />
            </Route>
            <Route exact path="/resources">
              <Resources />
            </Route>
            <Route exact path="/privacy">
              <Privacy />
            </Route>
            <Route exact path="/gallery">
              <Gallery />
            </Route>
            <Route exact path="/faqs">
              <FAQ />
            </Route>
            <Route exact path={RouteProductStyle()}>
              <ProductStylePage />
            </Route>

            <Route exact path={RouteProductPage()}>
              <ProductPage />
            </Route>
            <Route exact path="/order/checkout">
              <CheckoutPage />
            </Route>
            <Route exact path="/order">
              <OrderPage />
            </Route>
            <Route exact path={RouteOccasions()}>
              <Occasions />
            </Route>
            <Route exact path="/prices">
              <Prices />
            </Route>
            <Route exact path="/cart">
              <Cart />
            </Route>
            <Route exact path="/product-styles">
              <Redirect to="/product-styles/PRINTED-WRISTBANDS" />
            </Route>
            <Route exact path="/product-styles/tyvek">
              <TyvekWristbands />
            </Route>
            <Route exact path="/product-styles/in-stock">
              <Redirect to="/product-styles/tyvek" />
            </Route>
            <Route exact path="/register">
              <Register />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/forgot-password">
              <ForgotPassword />
            </Route>
            <Route path="/reset-password/:token" component={ResetPassword} />
            <Route exact path="/account">
              <Account />
            </Route>
            <Route exact path="/order/receipt">
              <Receipt />
            </Route>
            <Route exact path="/free-sample">
              <ProtectedRoute>
                <FreeSample />
              </ProtectedRoute>
            </Route>
            <Route exact path="/webhook">
              <Webhook />
            </Route>
            <Route
              path="/customer/verify-account/:token"
              component={EmailVerification}
            />
            <Route path="/get-quote" component={GetQuote} />
            <Route
              path="/social-login/:socialprovider/callback"
              component={SocialLogin}
            />
            <Route path="/upsell">
              <UpsellVerification />
            </Route>
            <Route path="/404">
              <NotFoundComponent />
            </Route>
            <Route>
              <Redirect to="/404" />
            </Route>
          </IonRouterOutlet>
        </IonReactRouter>
      </CartProvider>
    </CurrentCustomerProvider>
  </IonApp>
);

export default App;
