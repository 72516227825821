import axios, { AxiosResponse } from "axios";

const requestQuote = (
  token: string,
  customer_name: string,
  company_name: string | null = null,
  email: string,
  comments: string | null = null,
  cart_id: number,
): Promise<AxiosResponse> => {
  const headers = {
    Authorization: token,
    "Content-type": "application/json",
  };

  return axios.request({
    method: "POST",
    url: "/graphql",
    withCredentials: true,
    headers,
    data: {
      query: `
          mutation requestQuote($customer_name: String!
            $company_name: String
            $email: String!
            $comments: String
            $cart_id: ID!) {
            requestQuote(input: {
                customer_name: $customer_name
                company_name: $company_name
                email: $email
                comments: $comments
                cart_id: $cart_id
            }) {
              success
              message
            }
          }
        `,
      variables: {
        customer_name: customer_name,
        company_name: company_name,
        email: email,
        comments: comments,
        cart_id: cart_id,
      },
    },
  });
};

export default requestQuote;
