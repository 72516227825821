/*
*The form is used for adding and editing address. It has two modes: editable and not editable.
*The form is created using react-hook-form and ionics components.
In case of manual address entry, validation is completed using SmartyStreets API.
For providing addrress suggestions, Smarty Autocomplete API is used.
*/
import { useForm, Controller } from "react-hook-form";
import {
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonList,
  IonSpinner,
} from "@ionic/react";
import { useState, useContext, useEffect, useRef, createRef } from "react";
import environment from "../../../configs";
import SmartyStreetsSDK, { core } from "smartystreets-javascript-sdk";
import { CurrentCustomerContext } from "../../../contexts/currentCustomer";

//import customed hooks
import useLocalStorage from "../../../hooks/useLocalStorage";
import useClickOutside from "../../../hooks/useClickOutside";

//import services with graphql requests
import createCustomerAddress from "../../../services/customer/createCustomerAddress";
import updateCustomerAddress from "../../../services/customer/updateCustomerAddress";

//import interfaces
import { ICustomer } from "../../../types/ICustomer";
import { IFormInputError } from "../../../types/IFormInputError";
import { IAddressSuggestion } from "../../../types/Forms/IAddressSuggestion";
import { IAddressData } from "../../../types/Forms/IAddressData";

import AddressValidationForm from "./components/AddressValidationForm";
import { useLocation } from "react-router-dom";

import "./AccountForm.css";

//Interfaces for Address Form
interface SmartyStreetsResponse {
  result?: IAddressSuggestion[];
}

interface IAddressFormParams {
  currentCustomer: ICustomer;
  setAddressFormActive?: (active: boolean) => void;
  isGuest?: boolean;
}
type InputChangeEventDetail = {
  value?: string | undefined | null;
};

//Smarty Client for address autocomplete
const smartyStreetsClientAutocomplete = core.buildClient.usAutocompletePro(
  new core.SharedCredentials(environment.smartyKey),
);

//Smarty Client for address validation
const smartyStreetsClientValidation = core.buildClient.usStreet(
  new core.SharedCredentials(environment.smartyKey),
);
//Nessasary variables for address validation and autocomplete
const LookupAutocomplete = SmartyStreetsSDK.usAutocompletePro.Lookup;
const LookupValidation = SmartyStreetsSDK.usStreet.Lookup;

/*Address Form Component*/

const AddressForm: React.FC<IAddressFormParams> = ({
  currentCustomer,
  setAddressFormActive,
  isGuest = false,
}) => {
  // get token from local storage
  const [token] = useLocalStorage("token");
  // get stored customer data from local storage
  const [, setStoredCustomer] = useLocalStorage("customer");
  // get current customer data from React context
  const [, dispatch] = useContext(CurrentCustomerContext);

  // initialize state variables for editable status and form submission status
  const [isEditable, setIsEditable] = useState(true);
  const [isSaved, setIsSaved] = useState(false);
  const [backendErrors, setBackendErrors] = useState<IFormInputError[]>([]);
  const [isReadyToEdit, setIsReadyToEdit] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();

  // set address form active status in parent component
  useEffect(() => {
    if (setAddressFormActive) {
      setAddressFormActive(isEditable);
    }
  }, [isEditable]);

  //variables to indicate if address needs validation (address doesn't need validation in case of chosen suggested address)
  const [needValidation, setNeedValidation] = useState<boolean>(true);

  // if customer addfress is set up durting initial load, the form is no editable
  useEffect(() => {
    if (currentCustomer.addresses?.[0]?.id) {
      setIsEditable(false);
    }
  }, []);

  // initialize form control from react-hook-form with default values and validation rules
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
    trigger,
    clearErrors,
  } = useForm<IAddressData>({
    defaultValues: {
      address1: currentCustomer.addresses?.[0]?.address1 ?? "",
      address2: currentCustomer.addresses?.[0]?.address2 ?? "",
      city: currentCustomer.addresses?.[0]?.city ?? "",
      state: currentCustomer.addresses?.[0]?.state ?? "",
      postcode: currentCustomer.addresses?.[0]?.postcode ?? "",
    },
    mode: "all",
  });

  //Validations rules for the addreess form, using react-hook-form
  //doesn't alllows special symbols
  const isValidInput = (value: any) => {
    const regex = /^(?!\s)[\w#-\s,]*$/;
    return regex.test(value);
  };
  //checks if the value is a number and has 5 or 9 digits
  const isNumericInput = (value: string) => {
    const regex = /^\d{5}(-\d{4})?$/;
    return regex.test(value);
  };

  /*****  Address Autocomplete Functions *****/

  // initialize state variables for address suggestions, which appear when the user is typing in the address input
  const [suggestedAddresses, setSuggestedAddresses] = useState<
    IAddressSuggestion[]
  >([]);
  // initialize state variables for clearing suggested addresses
  const [clearSuggestedAddresses, setClearSuggestedAddresses] =
    useState<boolean>(false);

  // clear suggested addresses when clearSuggestedAddresses is true
  useEffect(() => {
    if (clearSuggestedAddresses) {
      setSuggestedAddresses([]);
    }
  }, [clearSuggestedAddresses]);

  //Setting variables for clearing suggested list if customer leaves the input and suggested list
  const inputRef = useRef<HTMLIonInputElement>(null);
  const listRef = useRef<HTMLIonListElement>(null);

  //using customed hook to indicate if customer leaves the input and suggested list
  useClickOutside(inputRef, listRef, () => {
    setClearSuggestedAddresses(true);
  });

  // initialize state variable, which indicated if the user needs suggestions
  const [needSuggestions, setNeedSuggestions] = useState<boolean>(true);
  // initialize state variable, which indicated if one of suggested addresses is selected
  const [isAddressSelected, setIsAddressSelected] = useState<boolean>(false);

  //Getting the list of suggestions for the address, when the customer tries to input address in the address1 field
  const validateAddress = async (address: string) => {
    if (needSuggestions === true) {
      if (address.length < 1) {
        setSuggestedAddresses([]); //if address is empty, clear suggested addresses
        return;
      }
      const lookup: any = new LookupAutocomplete(address); //type "any" is left because other found solutions don't work
      try {
        lookup.street = address;
        const response: SmartyStreetsResponse =
          await smartyStreetsClientAutocomplete.send(lookup);
        if (response.result && response.result.length > 0) {
          //if there are suggestions and the address field is not empty, set suggestion list
          const suggestedAddresses = response.result.map(
            //map suggestions to the format, which is needed for displaying
            (suggestion: IAddressSuggestion) => {
              return {
                streetLine: suggestion.streetLine,
                secondary: suggestion.secondary,
                entries: suggestion.entries,
                city: suggestion.city,
                state: suggestion.state,
                zipcode: suggestion.zipcode,
              };
            },
          );
          setSuggestedAddresses(suggestedAddresses); //set suggested address variables
        } else {
          setSuggestedAddresses([]); //if there are no suggestions, clear suggested addresses
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  //Getting apartment or suit number from the address. The function is needed if the customer chooses suggested address with more than one enytry (usually it means that the address has secondary address)
  const validateApt = async (query: string) => {
    const lookup: any = new LookupAutocomplete(query);
    lookup.maxSuggestions = 10;

    try {
      lookup.selected = query;
      const response: SmartyStreetsResponse =
        await smartyStreetsClientAutocomplete.send(lookup);

      if (response.result && response.result.length > 0) {
        const suggestedAddresses = response.result.map(
          (suggestion: IAddressSuggestion) => {
            return {
              streetLine: suggestion.streetLine,
              secondary: suggestion.secondary,
              entries: suggestion.entries,
              city: suggestion.city,
              state: suggestion.state,
              zipcode: suggestion.zipcode,
            };
          },
        );
        setSuggestedAddresses(suggestedAddresses);
      } else {
        setSuggestedAddresses([]);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Format Suggestion for making further request for secondary address suggestions. This function is nedded if selected address has secondary address
  function formatAutocompleteSuggestion(suggestion: IAddressSuggestion) {
    const street = suggestion.streetLine ? `${suggestion.streetLine} ` : "";
    const secondary = suggestion?.secondary ? `${suggestion.secondary} ` : "";
    const entries = suggestion?.entries !== 0 ? `(${suggestion.entries}) ` : "";
    const city = suggestion?.city ? `${suggestion.city} ` : "";
    const state = suggestion?.state ? `${suggestion.state}, ` : "";
    const zip = suggestion?.zipcode ? `${suggestion.zipcode}` : "";
    return street + secondary + entries + city + state + zip;
  }

  /*****  Manual Address Validation   *****/

  //initialize state variable to store address candiates, provided by address validation after submiting address
  const [candidates, setCandidates] = useState<any>([]);
  //variables for seting validation status (modified, suggested, confirmed, not-found)
  const [validationState, setValidationState] = useState<string>();
  //variables for storing address data, entered by customer
  const [addressDataEntered, setAddressDataEntered] = useState<any>();
  //variables for storing address data, suggested by SmartyStreets
  const [addressDataValidated, setAddressDataValidated] = useState<any>();
  //variables for storing first line of address, entered by customer
  const [savedAddressFirstLine, setSavedAddressFirstLine] = useState<string>(`${
    currentCustomer.addresses?.[0]?.address1 ?? ""
  }
  ${currentCustomer.addresses?.[0]?.address2 ?? ""}`);
  const [savedAddressSecondLine, setSavedAddressSecondLine] =
    useState<string>(` ${currentCustomer.addresses?.[0]?.city ?? ""}
  ${currentCustomer.addresses?.[0]?.state ?? ""}
  ${currentCustomer.addresses?.[0]?.postcode ?? ""}`);
  //variables to indicate if there is a problem in secondary address
  const [secondaryAddressProblem, setSecondaryAddressProblem] =
    useState<boolean>(false);
  //variables to store a message about secondary address problem
  const [secondaryAddressProblemMessage, setSecondaryAddressProblemMessage] =
    useState<string>("");
  const [addressMismatchLineOne, setAddressMismatchLineOne] =
    useState<boolean>(false);
  const [addressMismatchLineTwo, setAddressMismatchLineTwo] =
    useState<boolean>(false);

  //handling data when geting address candidate's list after manual address validation
  useEffect(() => {
    // if there are candidates, set the first candidate as the validated address
    if (candidates[0]) {
      setAddressDataValidated({
        address1: [
          candidates[0].components.primaryNumber,
          candidates[0].components.streetPredirection,
          candidates[0].components.streetName,
          candidates[0].components.streetSuffix,
        ]
          .filter(Boolean)
          .join(" "),
        address2:
          candidates[0]?.components?.secondaryDesignator &&
          candidates[0]?.components?.secondaryNumber
            ? `${candidates[0].components.secondaryDesignator} ${candidates[0].components.secondaryNumber}`
            : "",
        city: `${candidates[0].components.cityName}`,
        state: `${candidates[0].components.state}`,
        postcode: `${candidates[0].components.zipCode}`,
      });
      //checking if there is a problem in secondary address, set message about the problem
      if (candidates[0].analysis.enhancedMatch.includes("unknown-secondary")) {
        setSecondaryAddressProblem(true);
        setSecondaryAddressProblemMessage(
          "The address was saved without secondary address validation",
        );
      } else if (
        candidates[0].analysis.enhancedMatch.includes("missing-secondary")
      ) {
        setSecondaryAddressProblem(true);
        setSecondaryAddressProblemMessage(
          "Secondary address is probably missing",
        );
      } else {
        setSecondaryAddressProblem(false);
      }
      if (candidates[0] && candidates[0].analysis.footnotes) {
        setAddressMismatchLineOne(false);
        setAddressMismatchLineTwo(false);
        if (candidates[0] && candidates[0].analysis.footnotes.includes("L")) {
          setAddressMismatchLineOne(true);
        }
        if (candidates[0] && candidates[0].analysis.footnotes.includes("B")) {
          setAddressMismatchLineTwo(true);
        }
        if (
          candidates[0].analysis.footnotes === "A#" &&
          candidates[0].components.zipCode != addressDataEntered.postcode
        ) {
          setAddressMismatchLineTwo(true);
        }
      }
    }
  }, [candidates[0]]);

  //Setting validation status. Validation status depends on address validation result and comparing entered address with validated address
  //this use effect is triggered after address validation (function validateAddressOnSubmit) and seting addressDataValidated in use effect above
  //Validation status will affect form display

  useEffect(() => {
    if (candidates[0] && addressDataValidated) {
      if (candidates[0].metadata.recordType === undefined) {
        setValidationState("not-found");
      } else if (
        candidates[0].analysis.enhancedMatch.includes("postal-match") &&
        (!candidates[0].analysis.footnotes ||
          ((candidates[0].analysis.footnotes === "A#" ||
            candidates[0].analysis.footnotes === "S#" ||
            candidates[0].analysis.footnotes === "H#" ||
            candidates[0].analysis.footnotes === "S#A#" ||
            candidates[0].analysis.footnotes === "H#A#") &&
            candidates[0].components.zipCode === addressDataEntered.postcode))
      ) {
        setValidationState("validated");
        if (addressDataValidated) {
          saveAddress(addressDataValidated);
        }
      } else if (
        candidates[0].analysis.enhancedMatch === "postal-match" &&
        (candidates[0].analysis.footnotes === "N#" ||
          candidates[0].analysis.footnotes === "A#N#")
      ) {
        setValidationState("modified");
        if (addressDataValidated) {
          saveAddress(addressDataValidated);
        }
      } else {
        setValidationState("suggested");
      }
    }
  }, [addressDataValidated]);

  //function to validate address in case of manual entry
  const validateAddressOnSubmit = async (data: any) => {
    setAddressDataEntered(data); //wiill be used to compare entered address with validated address
    //setting data for address validation to SmartyStreets
    const lookup: any = new LookupValidation(data); //type "any" is left because other found solutions don't work
    lookup.street = data.address1;
    lookup.street2 = data.address2;
    lookup.city = data.city;
    lookup.state = data.state;
    lookup.zipcode = data.postcode;

    lookup.match = "enhanced";

    smartyStreetsClientValidation
      .send(lookup)
      .then((response) => {
        setCandidates(response.lookups[0].result); //in case of setting candidates, variables are set using useEffect
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  /**** Submiting and Saving Address Data ******/

  // handle form submission or updating or creating a customer's address. If the address is needed to be validated, validate it first. Otherwise, save it.
  const onSubmit = (data: IAddressData) => {
    setIsSaved(false);
    setClearSuggestedAddresses(true);
    if (needValidation === true && !location.pathname.includes("checkout")) {
      validateAddressOnSubmit(data);
    } else {
      saveAddress(data, false, isGuest);
    }
  };

  //function to handle form in case of successful address saving. It accepts address data, which cusomer prefered (entered Address or validated Address) and modificationDenied variable, which is true if customer denied to save modified address
  const handleSavedAddress = (data: any, modificationDenied?: boolean) => {
    setIsSaved(true);
    setIsEditable(false);
    //changing validation status after saving address
    if (
      (validationState && validationState === "suggested") ||
      validationState == "not-found" ||
      (validationState == "modified" && modificationDenied == true)
    ) {
      setValidationState("validated");
    }
    //updating address data in the form
    setValue("address1", data.address1);
    setValue("address2", data.address2);
    setValue("city", data.city);
    setValue("state", data.state);
    setValue("postcode", data.postcode);
    setSavedAddressFirstLine(`${data.address1}
    ${data.address2 ?? ""}`);
    setSavedAddressSecondLine(`
    ${data.city}
    ${data.state}
    ${data.postcode}`);
    setNeedValidation(true);
    setIsReadyToEdit(true);
  };

  //setting in case if address edition
  const editAddress = () => {
    setValidationState("");
    setIsEditable(true);
    setIsSaved(false);
  };

  //Handle save address. The function is called if adrress is saved without validation or if customer confirmed suggested address.
  //If the customer manually entried the address, the function is called asfter address validation.
  //if customer already has an address, the function updates it. If not, the function creates a new address.
  const saveAddress = (
    data: any,
    modificationDenied = false,
    isGuest = false,
  ) => {
    setIsReadyToEdit(false);
    setLoading(true);

    // if the customer already has an address, update it
    if (currentCustomer.addresses?.[0]?.id) {
      updateCustomerAddress(
        token,
        currentCustomer.addresses[0].id,
        data.address1,
        data.address2,
        data.city,
        data.state,
        data.postcode,
      )
        .then((response) => {
          setLoading(false);
          if (response.data.errors) {
            const errors: IFormInputError[] = [];
            const randomNumber = Math.floor(Math.random() * 100) + 1; // lazy approach
            response.data.errors.forEach((err: { message: string }) => {
              errors.push({
                message: err.message,
                id: `password-${randomNumber}`,
              });
            });

            setBackendErrors(errors);
          } else {
            setBackendErrors([]);
          }

          // update stored customer data and dispatch to update current customer data
          setStoredCustomer(
            JSON.stringify({
              ...currentCustomer,
              ...response.data.data.updateAddress,
            }),
          );
          dispatch({
            type: "SET_AUTHORIZED",
            payload: {
              ...currentCustomer,
              ...response.data.data.updateAddress,
            },
          });

          // update state variables for form submission status and editable status

          handleSavedAddress(data, modificationDenied);
        })
        .catch((error) => {
          console.error(error);
        });
    }
    // if the customer does not have an address, create one
    else {
      if (isGuest) {
        const firstName =
          currentCustomer.firstName && currentCustomer.firstName.trim() !== ""
            ? currentCustomer.firstName
            : "Guest";
        const lastName =
          currentCustomer.lastName && currentCustomer.lastName.trim() !== ""
            ? currentCustomer.lastName
            : "User";

        const email =
          currentCustomer.email && currentCustomer.email.trim() !== ""
            ? currentCustomer.email
            : "";

        setStoredCustomer(
          JSON.stringify({
            ...currentCustomer,
            firstName,
            lastName,
            email,
            addresses: [
              {
                id: 0,
                ...data,
                firstName,
                lastName,
                email,
              },
            ],
          }),
        );
        dispatch({
          type: "SET_GUEST_ADDRESS",
          payload: {
            ...currentCustomer,
            firstName,
            lastName,
            email,
            addresses: [
              {
                id: 0,
                ...data,
                firstName,
                lastName,
                email, // for admin dashboard
              },
            ],
          },
        });
        handleSavedAddress(data, modificationDenied);
      } else {
        createCustomerAddress(
          token,
          data.address1,
          data.address2,
          data.city,
          data.state,
          data.postcode,
        )
          .then((response) => {
            if (response.data.errors) {
              const errors: IFormInputError[] = [];
              const randomNumber = Math.floor(Math.random() * 100) + 1; // lazy approach
              response.data.errors.forEach((err: { message: string }) => {
                errors.push({
                  message: err.message,
                  id: `password-${randomNumber}`,
                });
              });

              setBackendErrors(errors);
            } else {
              setBackendErrors([]);
            }
            // update stored customer data and dispatch to update current customer data

            setStoredCustomer(
              JSON.stringify({
                ...currentCustomer,
                ...response.data.data.createAddress,
              }),
            );
            dispatch({
              type: "SET_AUTHORIZED",
              payload: {
                ...currentCustomer,
                ...response.data.data.createAddress,
              },
            });

            handleSavedAddress(data, modificationDenied);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  };
  //Handling key up and key down

  const [buttonRefs, setButtonRefs] = useState<any[]>([]); //the type should be <(RefObject<HTMLButtonElement> | null)[]>, but replaced to any because of linting/prettier conflict
  const [focusedButton, setFocusedButton] = useState(-1);
  useEffect(() => {
    if (focusedButton === -1) {
      // Focus the input field
      inputRef.current?.focus();
    } else {
      // Focus the appropriate button
      buttonRefs[focusedButton]?.current?.focus();
    }
  }, [focusedButton, buttonRefs, inputRef]);

  useEffect(() => {
    setButtonRefs((refs) =>
      Array(suggestedAddresses.length)
        .fill(null)
        .map((_, i) => refs[i] || createRef()),
    );
  }, [suggestedAddresses]);

  const handleKeyDown = (event: any) => {
    setNeedValidation(true);
    switch (event.key) {
      case "ArrowDown":
        event.preventDefault();
        // If we are at the end of the list, reset to 0, which is the first button. Otherwise increment focusedButton.
        setFocusedButton(
          focusedButton >= buttonRefs.length - 1 ? 0 : focusedButton + 1,
        );
        break;
      case "ArrowUp":
        event.preventDefault();
        // If we are at the start of the list or the input field, stay on the input field. Otherwise decrement focusedButton.
        setFocusedButton(focusedButton <= 0 ? 0 : focusedButton - 1);
        break;
      default:
        setFocusedButton(-1);
        break;
    }
  };

  // Manually validate form fields based on isAddressSelected
  const checkFormValidity = async () => {
    if (isAddressSelected) {
      clearErrors(); // Clear all errors manually
      const result = await trigger(); // Manually trigger validation to update isValid
      return result; // Return the result of the validation (true if valid)
    } else {
      await trigger(); // Trigger regular validation if no address is selected
      return isValid; // Rely on regular validation state
    }
  };

  useEffect(() => {
    if (isAddressSelected) {
      checkFormValidity();
    }
  }, [isAddressSelected]);

  /*
  Displaying the Address form. It depends on validation status and editable status. 
  *The form is created using react-hook-form and ionics components.
  */
  return (
    <IonCard className="account-form-card">
      <IonCardTitle className="account-form-card-title">
        {location.pathname === "/account" ? "Address" : ""}
        {!isEditable && isReadyToEdit && (
          <span
            id="add-address"
            className="account-form-action"
            onClick={() => {
              setIsEditable(true);
              setIsSaved(false);
              setValidationState("");
            }}
          >
            {currentCustomer?.addresses?.[0]?.id ? "Edit" : ""}
          </span>
        )}
      </IonCardTitle>
      <IonCardContent>
        {isSaved && <p>Your address has been saved.</p>}
        {validationState ? ( //if validation status is set, display the component AddressValidationFormaccording to the status
          <AddressValidationForm
            state={validationState}
            saveAddress={saveAddress}
            editAddress={editAddress}
            addressDataEntered={addressDataEntered}
            addressDataValidated={addressDataValidated}
            secondaryAddressProblem={secondaryAddressProblem}
            secondaryAddressProblemMessage={secondaryAddressProblemMessage}
            addressMismatchLineOne={addressMismatchLineOne}
            addressMismatchLineTwo={addressMismatchLineTwo}
          />
        ) : (
          <></>
        )}
        {isEditable ? (
          <form onSubmit={handleSubmit(onSubmit)} id="address">
            {backendErrors &&
              backendErrors.length > 0 &&
              backendErrors.map((error) => (
                <span key={error.id} className="account-form-error">
                  {error.message}
                </span>
              ))}

            {/***  Address 1 ***/}
            <Controller
              name="address1"
              control={control}
              render={({ field }) => (
                <IonItem>
                  <IonLabel position="floating">Address 1</IonLabel>
                  <IonInput
                    ref={inputRef}
                    value={field.value}
                    id="address1"
                    onIonInput={(
                      event: CustomEvent<InputChangeEventDetail>,
                    ) => {
                      if (needValidation) {
                        setClearSuggestedAddresses(false);
                        setNeedValidation(true);
                        if (
                          event.detail.value !== null &&
                          event.detail.value !== undefined &&
                          isSaved === false
                        ) {
                          validateAddress(event.detail.value);
                          field.onChange(event.detail.value);
                          if (isAddressSelected === true) {
                            setNeedSuggestions(true);
                            setIsAddressSelected(false);
                          }
                        }
                      }
                      () => setNeedValidation(true);
                    }}
                    onMouseDown={() => setNeedValidation(true)}
                    onKeyDown={handleKeyDown}
                    readonly={!isEditable}
                  />
                </IonItem>
              )}
            />

            {errors.address1 && errors.address1.type === "required" && (
              <span className="account-form-error">Address1 is required</span>
            )}
            {errors.address1 && errors.address1.type === "validate" && (
              <span className="account-form-error">
                Please avoid special symbols
              </span>
            )}
            {!clearSuggestedAddresses && suggestedAddresses.length > 0 && (
              <div className="absolute-list-container">
                <IonList
                  ref={listRef}
                  id="suggested-address-list"
                  overflow-scroll="true"
                >
                  {suggestedAddresses.map(
                    (suggestion: IAddressSuggestion, i: number) => (
                      <IonItem className="suggested-address-item" key={i}>
                        {suggestion.entries > 1 ? (
                          <button
                            ref={buttonRefs[i]}
                            onKeyDown={handleKeyDown} // Assign the ref here
                            type="button"
                            className="button-address-sugestion"
                            onClick={() => {
                              validateApt(
                                formatAutocompleteSuggestion(suggestion),
                              );
                              setFocusedButton(0);
                            }}
                          >
                            <span className="button-address-sugestion">
                              {suggestion.streetLine} {suggestion.secondary}
                              (Click to view {suggestion.secondary} entries),
                              {suggestion.city},{suggestion.state}{" "}
                              {suggestion.zipcode}
                            </span>
                          </button>
                        ) : (
                          <button
                            ref={buttonRefs[i]} // Assign the ref here
                            className="button-address-sugestion"
                            onKeyDown={handleKeyDown}
                            type="button"
                            onClick={() => {
                              setValue("address1", suggestion.streetLine);
                              setValue("address2", suggestion.secondary);
                              setValue("city", suggestion.city);
                              setValue("state", suggestion.state);
                              setValue("postcode", suggestion.zipcode);
                              setClearSuggestedAddresses(true);
                              setNeedSuggestions(false);
                              setIsAddressSelected(true);
                              setNeedValidation(false);

                              // Automatically submit the form on address selection if on the 'order/checkout' page
                              if (location.pathname === "/order/checkout") {
                                console.log("Submitting form");
                                console.log(needValidation);
                                handleSubmit(onSubmit)(); // Trigger the form submission programmatically
                              }
                            }}
                          >
                            <span className="button-address-sugestion">
                              {suggestion.streetLine} {suggestion.secondary},
                              {suggestion.city},{suggestion.state}{" "}
                              {suggestion.zipcode}
                            </span>
                          </button>
                        )}
                      </IonItem>
                    ),
                  )}
                </IonList>
              </div>
            )}

            {/**** Address 2 ***/}
            <Controller
              name="address2"
              control={control}
              rules={{ validate: isValidInput }}
              render={({ field }) => (
                <IonItem>
                  <IonLabel position="floating">Address 2</IonLabel>
                  <IonInput
                    id="address2"
                    value={field.value}
                    onIonInput={(e) => {
                      field.onChange(e);
                      () => setNeedValidation(true);
                    }}
                    readonly={!isEditable}
                  />
                </IonItem>
              )}
            />
            {errors.address2 && errors.address2.type === "validate" && (
              <span className="account-form-error">
                Please avoid special symbols
              </span>
            )}
            {/****  City ****/}
            <Controller
              name="city"
              control={control}
              rules={{ required: true, validate: isValidInput }}
              render={({ field }) => (
                <IonItem>
                  <IonLabel position="floating">City</IonLabel>
                  <IonInput
                    id="city"
                    value={field.value}
                    onIonInput={(e) => {
                      field.onChange(e);
                      () => setNeedValidation(true);
                    }}
                    readonly={!isEditable}
                  />
                </IonItem>
              )}
            />
            {errors.city && errors.city.type === "required" && (
              <span className="account-form-error">City is required</span>
            )}
            {errors.city && errors.city.type === "validate" && (
              <span className="account-form-error">
                Please avoid special symbols
              </span>
            )}
            {/***  State ****/}
            <Controller
              name="state"
              control={control}
              rules={{ required: true, validate: isValidInput }}
              render={({ field }) => (
                <IonItem>
                  <IonLabel position="floating">State</IonLabel>
                  <IonInput
                    id="state"
                    value={field.value}
                    onIonInput={(e) => {
                      field.onChange(e);
                      () => setNeedValidation(true);
                    }}
                    readonly={!isEditable}
                  />
                </IonItem>
              )}
            />
            {errors.state && errors.state.type === "required" && (
              <span className="account-form-error">State is required</span>
            )}
            {errors.state && errors.state.type === "validate" && (
              <span className="account-form-error">
                Please avoid special symbols
              </span>
            )}
            {/*** Zipcode ***/}
            <Controller
              name="postcode"
              control={control}
              rules={{ required: true, validate: isNumericInput }}
              render={({ field }) => (
                <IonItem>
                  <IonLabel position="floating">Postcode</IonLabel>
                  <IonInput
                    id="postcode"
                    value={field.value}
                    onIonInput={(e) => {
                      field.onChange(e);
                      () => setNeedValidation(true);
                    }}
                    readonly={!isEditable}
                  />
                </IonItem>
              )}
            />
            {errors.postcode && errors.postcode.type === "required" && (
              <span className="account-form-error">Postcode is required</span>
            )}
            {errors.postcode && errors.postcode.type === "validate" && (
              <span className="account-form-error">
                Zip code is not correct
              </span>
            )}
            <div className="account-form-button-container">
              {isEditable && location.pathname !== "/order/checkout" && (
                <IonButton type="submit" disabled={isValid === false}>
                  <span className="button-text">Save Address</span>
                </IonButton>
              )}
              {loading && <IonSpinner name="lines" />}
            </div>
          </form>
        ) : (
          <p className="saved-address">
            {savedAddressFirstLine},<br />
            {savedAddressSecondLine}
          </p>
        )}
      </IonCardContent>
    </IonCard>
  );
};

export default AddressForm;
